import React from "react";
import Meta from "./../components/Meta";
import { Typography, Box, Container } from "@mui/material";
import HeroSection from "../components/HeroSection";
import AboutImage from "../assets/about_page_image.jpg";

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <HeroSection
        bgImage={AboutImage}
        align={"center"}
        title={"What is Fairearth?"}
      ></HeroSection>
      <Container maxWidth="md" sx={{ py: { xs: 8, md: 7, lg: 5 } }}>
        <Box
          sx={{
            mx: "auto",
            mb: { lg: 16, sm: 8 },
            maxWidth: "sm",
            textAlign: "left",
          }}
        >
          <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
            Hello! Welcome to Fairearth
            <span role="img" aria-label="leaf">
              🌿
            </span>{" "}
            My name is Soumitri and I am the founder of Fairearth. Based out of
            Brooklyn, NY, I started Fairearth on two broad ideas - conscious
            consumerism and brand-consumer transparency. My desire to lead a
            more sustainable lifestyle and be more mindful of my consumption
            began in 2018, when I started noticing how wasteful my day-to-day
            life was. I had a genuine desire to be more mindful of what I
            consumed, understand where it came from and how it came about. A
            natural extension of this was being more mindful of the brands I
            supported.
          </Typography>
          <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
            Fairearth is really a place for brands to tell us, consumers, about
            their brand culture and how their values translate into their daily
            practices and their end product. If we really knew, from the source,
            what we're about to consume before doing so, how would that change
            our habits?
          </Typography>
          <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
            For me, I truly believe Fairearth is for everyone - all are welcome
            here. This place is just as much for those who do not care about the
            planet or their consumption habits as it is for those who do. There
            are plenty of ways I could do better in my consumption habits and
            Fairearth is about putting our foot forward in the right direction -
            no matter how small that step is. May this site be a source of
            inspiration and a catalyst for the sustainable revolution we so
            passionately strive for.
          </Typography>
          <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
            If you'd like to, please reach me at{" "}
            <a
              rel="noopener noreferrer"
              href="mailto:soumitri@fairearth.eco"
              target="_blank"
            >
              soumitri@fairearth.eco
            </a>
            . I check my emails everyday and will follow up within 24 hours,
            let's do this!
          </Typography>
        </Box>
      </Container>
    </>
  );
}

export default AboutPage;
