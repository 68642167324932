import React from "react";
import Meta from "./../components/Meta";
import Container from "@mui/material/Container";
import Section from "../components/Section";
import Grid from "@mui/material/Grid";
import BlogCard from "../components/BlogCard";
import { allBlogs } from "../resources/blogs";

function BlogsPage(props) {
  return (
    <>
      <Meta
        title={"Blog"}
        description="Learn about us from our blog posts"
      />
      <Section bgColor="default" size="medium">
        <Container maxWidth="md">
          <Grid container spacing={5} sx={{ mt: 3, mx: 2 }}>
            {allBlogs.map((blog, key) => (
              <BlogCard key={key} blog={blog}></BlogCard>
            ))}
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default BlogsPage;
