import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, Button, Menu, MenuItem } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  menuitem: {
    fontSize: "15px",
    color: "#000",
  },
  // iconRotateOpened: {
  //   transform: "rotate(180deg)",
  //   transition: "transform 0.3s ease-in-out",
  // },
  // iconRotateClosed: {
  //   transform: "rotate(0deg)",
  //   transition: "transform 0.3s ease-in-out",
  // },
}));

function FilterDropdown(props) {
  const { header, options, updateSelected, selected } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleButtonHover = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item xs={12} md={"auto"} align="left">
      <Button
        className={classes.menuitem}
        id="basic-button-1"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        // TODO: when moving mouse off of button to another button without hovering the menu dropdown,
        // the second menu does not open unless you click off the first menu
        // https://stackoverflow.com/questions/55318477/how-to-make-material-ui-menu-based-on-hover-not-click
        onClick={handleButtonHover}
        onMouseOver={handleButtonHover}
      >
        {header}
        {/* TODO: create a transition motion more natural */}
        {open ? (
          <ExpandLessIcon fontSize="small" />
        ) : (
          <ExpandMoreIcon fontSize="small" />
        )}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          onMouseLeave: handleClose,
        }}
        sx={{'& .MuiMenu-paper': {
          borderRadius: "15px",
      }}}
      >
        {Object.values(options).map((option) => (
          <MenuItem
            value={option}
            onClick={() => {
              updateSelected(option);
            }}
            key={option}
            sx={{
              fontSize: "15px",
              fontWeight: "500",
            }}
          >
            <Grid container>
              <Grid item>{option}</Grid>
              <Grid
                item
                xs
                container
                alignItems="center"
                direction="row-reverse"
              >
                {selected.has(option) && <CloseIcon fontSize="inherit" />}
              </Grid>
            </Grid>
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
}
export default FilterDropdown;
