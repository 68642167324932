import React from "react";
import * as colors from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
import {
  useTheme,
  createTheme,
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from "@mui/material/styles";
const themeConfig = {
  // Light theme
  light: {
    palette: {
      mode: "light",
      primary: {
        main: colors.common.white,
      },
      secondary: {
        main: colors.common.white,
      },
      background: {
        default: colors.common.white,
      },
      footer: {
        main: "#304C42",
      }
    },
  },

  // Dark theme
  dark: {
    palette: {
      mode: "dark",
      primary: {
        // Same as in light but we could
        // adjust color hue if needed
        main: colors.indigo["500"],
      },
      secondary: {
        main: colors.pink["500"],
      },
      background: {
        default: colors.common.white,
        paper: colors.grey["800"],
      },
    },
  },

  // Values for both themes
  common: {
    typography: {
      fontSize: 14,
      fontFamily: [
        "Source Sans Pro",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
      fontWeightRegular: 700,
      button: { textTransform: "none" },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1200,
        xl: 1920,
      },
    },
    // Override component styles
    overrides: {
      // Global styles
      MuiCssBaseline: {
        "@global": {
          "#root": {
            // Flex column that is height
            // of viewport so that footer
            // can push self to bottom by
            // with auto margin-top
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            // Prevent child elements from
            // shrinking when content
            // is taller than the screen
            // (quirk of flex parent)
            "& > *": {
              flexShrink: 0,
            },
          },
        },
      },
    },
  },
};

function getTheme(name) {
  // Create MUI theme from themeConfig
  return createTheme(adaptV4Theme({
    ...themeConfig[name],
    // Merge in common values
    ...themeConfig.common,
    overrides: {
      // Merge overrides
      ...(themeConfig[name] && themeConfig[name].overrides),
      ...(themeConfig.common && themeConfig.common.overrides),
    },
  }));
}

export const ThemeProvider = (props) => {
  // Get MUI theme object
  // const themeName = isDarkMode ? "dark" : "light";
  const themeName = "light";
  const theme = getTheme(themeName);

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        {/* Set global  MUI styles */}
        <CssBaseline />
        {props.children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};

// Hook for detecting dark mode and toggling between light/dark
// More convenient than reading theme.palette.mode from useTheme
export function useDarkMode() {
  // Get current Material UI theme
  const theme = useTheme();
  // Check if it's the dark theme
  const isDarkMode = theme.palette.mode === "dark";
  // Return object containing dark mode value and toggle function
  return { value: isDarkMode, toggle: theme.palette.toggle };
}
