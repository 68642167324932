import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import Section from "./Section";
import Newsletter from "../components/Newsletter";
import { LogoFont } from "../resources/constants";
import NewsletterSignupImage from "../assets/newsletter_signup_image.jpg";
import Hidden from "@mui/material/Hidden";

function NewsletterSection(props) {
  const containerStyle = {
    height: "280px", // Set the height of the section to 50px
    overflow: "hidden", // Hide overflow content
  };

  const imageStyle = {
    width: "100%",
    height: "auto", // Maintain image aspect ratio
    objectFit: "cover", // Maximize the image, no borders
  };

  const rightColumnStyle = {
    backgroundColor: "#F7F5F3", // Set the background color for the right one-third
    padding: 0, // Remove padding
    position: "relative",
  };

  const headlineStyle = {
    position: "absolute",
    top: "16%",
    left: "10%",
  };

  const headlineTextStyle = {
    fontFamily: LogoFont,
    fontWeight: "500",
  };

  return (
    <Section size="auto">
      <Grid container spacing={0} style={containerStyle}>
        <Hidden smDown>
          <Grid item sm={7}>
            {/* TODO:: Fix the image to not have extra spacing after the hidden area.
            Then redo the centering style for the Subscribe section.
            Consider Photoshopping the image to what is shown. */}
            <img
              src={NewsletterSignupImage}
              alt="Newsletter sign up"
              style={imageStyle}
            />
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={5} style={rightColumnStyle}>
          <Box style={headlineStyle}>
            <Typography variant="h4" style={headlineTextStyle} gutterBottom>
              Subscribe to our Newsletter
            </Typography>
            <Newsletter
              buttonText="Sign up"
              inputPlaceholder="Email..."
              subscribedMessage="You're subscribed now! Thanks"
            />
          </Box>
        </Grid>
      </Grid>
    </Section>
  );
}

export default NewsletterSection;
