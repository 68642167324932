import React from "react";
import { Typography } from "@mui/material";
import fisionPollastic from "./images/fision/fision-pollastic.jpg";
import { LogoFont } from "../../resources/constants";

function Fision() {
  return (
    <>
      {/* <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/dllS-bG4jUM?si=fItT21oJw_kTSfCC"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe> */}
      <Typography
        variant="h4"
        mb={2}
        mt={4}
        sx={{ fontFamily: LogoFont, fontWeight: "400" }}
      >
        What Makes The Fision Unique?
      </Typography>
      <Typography mb={4} variant="body1" sx={{ fontWeight: "normal" }}>
        We go the extra mile in each step of creating our tops. From
        regenerative farming to blockchain technology, from traceable packaging
        created from ocean bound plastic pollution to sustainable spinning, we
        look at everything in an effort to push boundaries for a more positive
        and sustainable future.
      </Typography>
      <Typography
        variant="h4"
        mb={2}
        mt={2}
        sx={{ fontFamily: LogoFont, fontWeight: "400" }}
      >
        Top Values
      </Typography>
      <Typography
        variant="h5"
        mb={2}
        sx={{ fontWeight: "bold", color: "#304c42" }}
      >
        Supply Chain Transparency
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        Our company is 100% transparent about the material sourcing of our tops.
        We use Good Earth Cotton®, which is grown using regenerative farming
        methods that have a climate positive effect. In the context of
        sustainable fashion, we utilize blockchain to create a transparent and
        secure supply chain. With FibreTrace® Verified, we embed an invisible
        identifier into the Good Earth Cotton's raw fiber. This marker is
        scanned and verified at each step in the process, creating an immutable
        record. When you scan our QR code, you can follow the journey of your
        T-shirt or sweatshirt, essentially sewing transparency into the supply
        chain. Next, we expertly spin the cotton into yarn with the help of
        ECOLIFE by Belda Llorens®, a company known for its pioneering efforts in
        sustainable spinning. We transform raw cotton without using any harmful
        chemical additives while maintaining a certified zero carbon footprint.
      </Typography>
      <Typography
        variant="h5"
        mb={2}
        sx={{ fontWeight: "bold", color: "#304c42" }}
      >
        Made in the USA
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        We also take steps to ensure that our manufacturing processes are as
        environmentally friendly as possible. We knit, finish, cut, sew, dye,
        and print our fabrics and garments all in Los Angeles, California. This
        approach allows us to minimize our carbon footprint and uphold our
        commitment to quality, sustainability, and ethical manufacturing. Our
        products undergo garment dyeing, which means we apply color after the
        T-shirts are cut and sewn. This approach minimizes water usage compared
        to traditional methods. At The Fision, we exclusively use low-impact
        dyes without any toxic chemicals, and we combine them with baking soda,
        water, and vinegar.
      </Typography>
      <Typography
        variant="h5"
        mb={2}
        sx={{ fontWeight: "bold", color: "#304c42" }}
      >
        Recycled Packaging
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        For packaging, we use POLLAST!C™ mailers and polybags from Better
        Packaging Co. These packaging materials are made from 100% recycled
        ocean-bound plastic pollution. Independent life cycle analyses have
        demonstrated that POLLAST!C mailers are the lowest carbon form of
        packaging commercially available.
        <br />
        <br />
        <img
          src={fisionPollastic}
          height={"auto"}
          width={"70%"}
          alt={"pollastic packaging"}
        />
      </Typography>
    </>
  );
}

export default Fision;
