import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MenuIcon from "@mui/icons-material/Menu";
import { LogoFont } from "../resources/constants";

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: "none",
    color: "black",
    fontSize: "20px",
    fontWeight: "normal",
  },
  icon: {
    color: "white",
  },
  logo: {
    cursor: "pointer",
    color: "black",
    fontFamily: LogoFont,
    fontWeight: "500",
  },
}));

function DrawerComponent() {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <>
      <Drawer
        sx={{ zIndex: 100000 }}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link href="/about">
                <Typography className={classes.link}>About</Typography>
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link href="/partnerships">
                <Typography className={classes.link}>Partnerships</Typography>
              </Link>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)} size="large">
        <MenuIcon />
      </IconButton>
      <Link href="/" style={{ textDecoration: "none" }}>
        <Typography variant="h3" className={classes.logo}>
          fairearth.
        </Typography>
      </Link>
    </>
  );
}
export default DrawerComponent;
