import React from "react";
import { Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import { LogoFont } from "../resources/constants";

function CompanyHeadlineSection(props) {
  const { company } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {!isMobile ? (
        <Grid container>
          <Grid
            item
            md={7}
            paddingTop={"160px"}
            paddingBottom={"160px"}
            pl={{ lg: 5, md: 3 }}
            sx={{ backgroundColor: "#F7F5F3" }}
          >
            <Typography
              variant="h1"
              sx={{ fontFamily: LogoFont, fontWeight: "500" }}
            >
              {company.name}
            </Typography>
          </Grid>
          <Grid
            item
            md={5}
            display={"flex"}
            justifyContent={"flex-end"}
            maxHeight={432}
            backgroundColor={"#F7F5F3"}
          >
            {company.headlineImage1 && (
              <img src={company.headlineImage1} height={"100%"} alt={company.headlineImage1.name}/>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item md={7} sx={{ backgroundColor: "#F7F5F3" }}>
            {!isMobile && (
              <Typography
                variant="h1"
                pl={{ lg: 5, md: 3 }}
                mt={30}
                sx={{ fontFamily: LogoFont, fontWeight: "500" }}
              >
                {company.name}
              </Typography>
            )}
          </Grid>
          <Grid item md={5}>
            <img
              height={"100%"}
              width={"100%"}
              src={company.headlineImage1}
              alt={company.headlineImage1.name}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default CompanyHeadlineSection;
