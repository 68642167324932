import React from "react";
import { Icon, Tooltip } from "@mui/material";

function Certification(props) {
  const { icon, text } = props;

  return (
      <Tooltip title={text} arrow sx={{ bgcolor: "#304C42"}}>
        <Icon sx={{ ml: 0.5, height: "80px", width: "80px" }}>
          <img src={icon} height={"80px"} width={"80px"} alt={"certificate"} />
        </Icon>
      </Tooltip>
  );
}

export default Certification;
