import React, { useState } from "react";
import { TextField, Grid, Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import newsletter from "./../util/newsletter";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    "&::placeholder": {
      textAlign: "left",
      fontWeight: "500",
    },
    fontWeight: "500",
  },
  buttonStyle: {
    marginTop: "15px",
    backgroundColor: "white",
    color: "#304C42",
    borderRadius: "50px",
    height: "38px",
    border: "none", // Remove borders
    "&:hover": {
      backgroundColor: "#304C42", // Background color on hover (transparent)
      color: "white", // Text color on hover
    },
  },
}));

function Newsletter(props) {
  const classes = useStyles();
  const [subscribed, setSubscribed] = useState(false);

  const { handleSubmit, register, errors } = useForm();

  const onSubmit = ({ email }) => {
    setSubscribed(true);
    // Parent component can optionally
    // find out when subscribed.
    props.onSubscribed && props.onSubscribed();
    // Subscribe them
    newsletter.subscribe({ email });
  };

  return (
    <>
      {subscribed === false && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={9}>
              <TextField
                variant="standard"
                type="email"
                name="email"
                error={errors.email ? true : false}
                helperText={errors.email && errors.email.message}
                fullWidth={true}
                placeholder="Email..."
                sx={{
                  borderRadius: "20px",
                  padding: "2px",
                  paddingLeft: "20px",
                }}
                inputRef={register({
                  required: "Please enter an email address",
                })}
                style={{
                  backgroundColor: "#E5C185",
                  borderRadius: "50px",
                  "&::placeholder": {
                    textAlign: "left",
                  },
                  fontWeight: "500",
                }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: classes.input,
                  },
                }}
                InputLabelProps={{
                  style: {
                    marginLeft: "10px",
                    marginBottom: "20px",
                    fontFamily: [
                      "Source Sans Pro",
                      '"Helvetica Neue"',
                      "Arial",
                      "sans-serif",
                    ].join(","),
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Box display="flex" alignItems="stretch" clone="true">
              <Grid item={true} xs="auto">
                <Button
                  variant="contained"
                  disableElevation={true}
                  color={props.buttonColor}
                  size="large"
                  type="submit"
                  className={classes.buttonStyle}
                >
                  {props.buttonText}
                </Button>
              </Grid>
            </Box>
          </Grid>
        </form>
      )}

      {subscribed === true && <div>{props.subscribedMessage}</div>}
    </>
  );
}

export default Newsletter;
