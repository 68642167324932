import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

function HeroSection(props) {
  return (
    <Section
      bgColor={props.bgColor}
      size={"large"}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Box textAlign={props.align}>
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={1}
            // invert the text color
            // style={{filter: `invert(1)`,
            // mixBlendMode: "difference"}}
          />
        </Box>
      </Container>
    </Section>
  );
}

export default HeroSection;
