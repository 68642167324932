import React from 'react';
import WelcomeToFairearthBlogPost from "../components/blogs/WelcomeToFairearth";

export const allBlogs = [
    {
        "name": "Welcome to Fairearth!",
        "path": "welcome-to-fairearth",
        "module": <WelcomeToFairearthBlogPost date={"Thursday, the 1st of June, 2023"} title={"Welcome to Fairearth!"}></WelcomeToFairearthBlogPost>,
        "desc": "Hello here we go",
        "date": "Thursday, the 1st of June, 2023"
    }
]