import React, { useState } from "react";
import { Box, Grid, Container, Typography } from "@mui/material";
import Section from "../components/Section";
import CompanyCard from "../components/CompanyCard";
import { AnimatePresence } from "framer-motion/dist/framer-motion";
import FilterBox from "../components/FilterBox";
import HeroSection from "../components/HeroSection";
import clothing_image from "../assets/logo/PNG/clothing-by-thom-bradley.jpg";

// temporarily disable companies from being indexed
import { allCompanies } from "../resources/companies";
// let allCompanies = [];

function NoResults() {
  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      <Typography variant="body1" sx={{ fontWeight: "normal", my: 8 }}>
        Fairearth is still onboarding brands - Come back another day for more
        data!
      </Typography>
    </Box>
  );
}

function CompanyList(props) {
  const { companies } = props;
  return (
    <Grid mt={-1.5} container={true} justifyContent="flex-start" spacing={4}>
      {companies.map((company, key) => (
        <CompanyCard key={key} company={company} />
      ))}
    </Grid>
  );
}

function Results(props) {
  const { query, companies } = props;
  if (companies.length === 0) {
    return <NoResults />;
  }
  if (query != null && query.length > 0) {
    const company = companies.find((c) => c["name"] === query);
    return company ? <CompanyList companies={[company]} /> : <NoResults />;
  } else {
    return <CompanyList companies={companies} />;
  }
}

function FilterPage(props) {
  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState(new Set());
  const [companies, setCompanies] = useState(allCompanies);

  const resetAll = () => {
    setSelected(new Set());
    setCompanies(allCompanies);
  };

  const updateSelected = (option) => {
    setSelected((currSelected) => {
      if (currSelected.has(option)) {
        currSelected.delete(option);
      } else {
        currSelected.add(option);
      }
      return currSelected;
    });
    setCompanies(() => {
      if (selected.size === 0) {
        return allCompanies;
      }
      return allCompanies.filter((company) => {
        for (const value of company.filters) {
          if (selected.has(value)) {
            return true;
          }
        }
        return false;
      });
    });
  };
  return (
    <>
      <HeroSection
        bgImage={clothing_image}
        align={"left-align"}
        title={"find brands you love"}
      ></HeroSection>
      <Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <FilterBox
          setQuery={setQuery}
          selected={selected}
          setSelected={setSelected}
          setCompanies={setCompanies}
          updateSelected={updateSelected}
          resetAll={resetAll}
        />
        <Container maxWidth="md">
          <AnimatePresence>
            <Results query={query} companies={companies} />
          </AnimatePresence>
        </Container>
      </Section>
    </>
  );
}

export default FilterPage;
