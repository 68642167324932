import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

function CompanyMain(props) {
  const { description, content } = props;

  return (
    <Grid item xs={12} md={8} mx={{lg: 0, md: 0}} px={{ lg: 10, md: 3}} pt={4} sx={{ bgcolor: "white" }}>
      <Typography mb={0} variant="h5" sx={{ fontWeight: "normal" }}>
        {description}
      </Typography>
      <br />
      {content}
    </Grid>
  );
}

export default CompanyMain;
