import React from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  useTheme,
  useMediaQuery,
  Grid,
  Link,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DrawerComponent from "./Drawer";
import fairearthBrandmark from "../assets/logo/PNG/2023.7.7_fairearth_side_logo_two_color.png";

const useStyles = makeStyles((theme) => ({
  appbar: {
    zIndex: 10000,
  },
  nav: {
    color: "black",
    fontSize: "24px",
    fontWeight: "400",
    marginLeft: "1.2rem",
    marginRight: "1.2rem",
    textDecoration: "none",
  },
}));

function Navbar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AppBar
      position="sticky"
      color="primary"
      elevation={0}
      className={classes.appbar}
    >
      <CssBaseline />
      <Toolbar>
        {isMobile && <DrawerComponent />}
        {!isMobile && (
          <Grid container>
            <Grid item xs={3}>
              <Link href="/" style={{ textDecoration: "none" }}>
                <img
                  src={fairearthBrandmark}
                  width={"100%"}
                  alt={"fairearth logo"}
                />
              </Link>
            </Grid>
            <Grid
              item
              mt={4}
              xs={9}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                fontSize: "25px",
              }}
            >
              <Link className={classes.nav} href="/about">
                About
              </Link>
              <Link className={classes.nav} href="/partnerships">
                Partnerships
              </Link>
            </Grid>
          </Grid>
        )}
      </Toolbar>
    </AppBar>
  );
}
export default Navbar;
