import React, { useState } from "react";
import { Typography, Link, Grid } from "@mui/material";
import { Pinterest, Instagram } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import Section from "./Section";
import fairearthWhiteLogo from "../assets/logo/PNG/2023.9.11_fairearth_brandmark_white.png";
import fairearthGoldLogo from "../assets/logo/PNG/2023.9.11_fairearth_brandmark_gold.png";
import { useMediaQuery } from "@mui/material";


const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    color: "inherit",
    margin: "1.2rem",
  },
  image: {
    width: "150px", // Set your desired width
    height: "auto", // Adjust the height proportionally
    marginLeft: "2.2rem",
    transition: "0.3s", // Add a smooth transition
    "&:hover": {
      transform: "scale(1.1)", // Optional: Add a scaling effect on hover
    },
  },
  footerStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem 0", // Adjust the padding as needed
  },
}));

function Footer(props) {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm")); // Define the breakpoint (sm for small screens)

  const footerContainerStyle = {
    justifyContent: isSmallScreen ? "center" : "space-between", // Center the content on small screens, use space-between on larger screens
  };

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      padding={0}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: 2 }}
        style={footerContainerStyle} // Apply the desired alignment to the flex container
      >
        <Grid item>
          <Link href="/">
            <FooterLogo />
          </Link>
        </Grid>
        <Grid item>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            my={1}
          >
            <Typography variant="h5" fontWeight="400">
              <Link href="/about" className={classes.link}>
                About
              </Link>
              <Link href="/partnerships" className={classes.link}>
                Partnerships
              </Link>
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            my={1}
          >
            <a
              href="https://www.pinterest.com/faireartheco"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              <Pinterest fontSize="small" />
            </a>
            <a
              href="https://www.instagram.com/fairearth.eco/?hl=en"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              <Instagram fontSize="small" />
            </a>
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Typography
              variant="body1"
              color="textSecondary"
              align="right"
              className={classes.link}
              fontWeight="400"
            >
              {props.copyright}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Section>
  );
}

export default Footer;

const FooterLogo = () => {
  const classes = useStyles();
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <img
      src={hovered ? fairearthGoldLogo : fairearthWhiteLogo}
      alt="Fairearth Logo"
      className={classes.image}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
};
