import React from "react";
import { Link, Typography, Grid } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { LogoFont } from "../resources/constants";
import greenLeaf from "../assets/illustration/2023.9.6-illustration_green_leaf_small.svg";
import { certificationFilters, clothingFilters, materialsFilters } from "../resources/filters";
import analytics from "../util/analytics";

function CompanySidebar(props) {
  const { website, certifications, shipping, filters, location, cost } = props;

  return (
    <Grid item xs={12} md={4} sx={{ bgcolor: "#F7F5F3" }} pl={{ lg: 5, md: 3 }} pt={4} pr={{ lg: 5, md: 3 }}>
      <Typography variant="h5" sx={{ fontWeight: "500" }}>
        <strong>Website: </strong>
        <Link
          target="_blank"
          component="a"
          href={website.url}
          sx={{ ml: 0.5, color: "#000", fontWeight: "normal" }}
          onClick={() => analytics.track("` URL clicked")}
        >
          Visit {website.nick}
          <OpenInNewIcon sx={{ my: "-5px" }}></OpenInNewIcon>
        </Link>
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ mt: 2, mb: 2, fontWeight: "500" }}>
        <strong>Shipping: </strong>
        {shipping.join(" \\ ")}
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ mb: 2, fontWeight: "500" }}>
        <strong>Location: </strong> {location}
      </Typography>
      {/* Price tag ranges from:
      $: 1-10
      $$: 10-50
      $$$: 50-100
      $$$$: 100+ */}
      <Typography variant="h5" gutterBottom sx={{ mb: 2, fontWeight: "500" }}>
        <strong>Price: </strong>
        {Array.from({ length: cost }, (_, i) => (
          <AttachMoneyIcon
            fontSize="medium"
            sx={{ mx: "-4px", my: "-5px", stroke: "#F7F5F3", strokeWidth: 0.5 }}
          ></AttachMoneyIcon>
        ))}
        {Array.from({ length: 4 - cost }, (_, i) => (
          <AttachMoneyIcon
            fontSize="medium"
            sx={{
              mx: "-4px",
              my: "-5px",
              stroke: "#F7F5F3",
              strokeWidth: 0.5,
              color: "grey",
            }}
          ></AttachMoneyIcon>
        ))}
      </Typography>
      <Typography variant="h4" mt={6} gutterBottom sx={{ fontFamily: LogoFont, fontWeight: "normal" }}>
        Values
      </Typography>
      <ul
        style={{
          paddingLeft: 0,
          listStyle: "none",
        }}
      >
        {filters
          .filter(
            (item) =>
              !Object.values(clothingFilters).includes(item) &&
              !Object.values(materialsFilters).includes(item) &&
              !Object.values(certificationFilters).includes(item)
          )
          .map((item, index) => (
            <li key={index} style={{ fontWeight: "500" }}>
              <img src={greenLeaf} alt={"bullet point"} width={"20"} height={"20"} />
              {item}
            </li>
          ))}
      </ul>
      <Typography variant="h4" mt={6} gutterBottom sx={{ fontFamily: LogoFont, fontWeight: "normal" }}>
        Clothing
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: "500" }}>
        {filters.filter((item) => Object.values(clothingFilters).includes(item)).join(" \\ ")}
      </Typography>
      <Typography variant="h4" mt={6} gutterBottom sx={{ fontFamily: LogoFont, fontWeight: "normal" }}>
        Materials
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: "500" }}>
        {filters.filter((item) => Object.values(materialsFilters).includes(item)).join(" \\ ")}
      </Typography>
      {certifications.length > 0 && (
        <>
          <Typography variant="h4" mt={6} gutterBottom sx={{ fontFamily: LogoFont, fontWeight: "normal" }}>
            Certifications
          </Typography>
          {certifications.map((certification, key) => (
            // react.fragment is only here to remove a key error warning
            <React.Fragment key={key}>{certification}</React.Fragment>
          ))}
        </>
      )}
    </Grid>
  );
}

export default CompanySidebar;
