export const filters = {
  Planet: "Planet",
  Labour: "Labour",
  Materials: "Materials",
  Clothing: "Clothing",
  Certifications: "Certifications",
  Community: "Community"
}

export const planetFilters = {
  MadeInUSA: "Made in the USA",
  MadeInCanada: "Made in Canada",
  MadeInEU: "Made in Europe",
  MadeInAsia: "Made in Asia",
  MadeInAfrica: "Made in Africa",
  NetZero: "Net Zero",
  RecyclablePackaging: "Recyclable Packaging",
  RecycledPackaging: "Recycled Packaging",
  SustainablePackaging: "Sustainable Packaging",
  CarbonNeutrality: "Carbon Neutrality",
  RenewableEnergy: "Renewable Energy",
  WasteReduction: "Waste Reduction",
  ZeroWaste: "Zero Waste",
  EcofriendlyMaterials: "Ecofriendly Materials"
};

export const materialsFilters = {
  Recycled: "Recycled",
  OrganicCotton: "Organic Cotton",
  Deadstock: "Deadstock",
  Tencel: "Tencel™",
  Plastics: "Plastics",
  Plant: "Plant",
  Wool: "Wool",
  BananaCrepe: "Banana Crepe",
  CactusLeather: "Cactus Leather",
  PYRATEX: "PYRATEX®",
  Regenerative: "Regenerative",
  Leather: "Leather",
  Bamboo: "Bamboo"
};

export const labourFilters = {
  TraceableSuppliers: "Traceable Suppliers",
  FairWages: "Fair Wages",
  WomenManufactured: "Women-Manufactured",
  WomenOwned: "Women-Owned",
  FamilyOwned: "Family-Owned",
  SmallScaleProduction: "Small Scale Production",
  ProductionFacilitiesOwnership: "Production Facilities Ownership"
};

export const clothingFilters = {
  Tops: "Tops",
  Bottoms: "Bottoms",
  Outerwear: "Outerwear",
  Intimates: "Intimates",
  Footwear: "Footwear",
  Dresses: "Dresses",
  Accessories: "Accessories",
  Swimwear: "Swimwear",
  Women: "Women",
  Men: "Men",
  Children: "Children",
};

export const certificationFilters = {
  GlobalOrganicTextileStandard: "Global Organic Textile Standard",
  Fairtrade: "Fair Trade",
  ForestStewardshipCouncil: "Forest Stewardship Council",
  USDA: "US Department of Agriculture (USDA)",
  SoilAssociation: "Soil Association",
  BCorp: "B Corporation",
  C2C: "Cradle To Cradle Certified",
  LeatherWorkingGroup: "Leather Working Group",
  GlobalRecycleStandard: "Global Recycle Standard",
  OEKOTEX: "OEKO-TEX",
  Bluesign: "bluesign",
};

export const communityFilters = {
  EmployeeWellbeing: "Employee Well-Being",
  Philanthropy: "Philanthropy",
  ProductSafety: "Product Safety",
  SupplierDiversity: "Supplier Diversity",
  SupplyChainTransparency: "Supply Chain Transparency"
};

export const promotionFilters = {
  Exposure: "Exposure to conscious consumers",
  ShareBrandValues: "Share brand values on a profile",
  MakesBrandUnique: "Expand on what makes the brand unique",
};
