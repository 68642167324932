import React from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import LegalPage from "./legal";
import CompanyPage from "./company";
import { Switch, Route, Router } from "./../util/router";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import "./../util/analytics";
import { ThemeProvider } from "./../util/theme";
import {
  allCompanies,
  allCompaniesWithUnindexedCompanies,
} from "../resources/companies";
import { allBlogs } from "../resources/blogs";
import BlogsPage from "./blogs";
import PartnershipsPage from "./partnerships";

function App(props) {
  return (
    <ThemeProvider>
      <>
        <Router>
          <>
            <Navbar color="default" />

            <Switch>
              <Route exact path="/" component={IndexPage} />

              <Route exact path="/about" component={AboutPage} />

              <Route exact path="/faq" component={FaqPage} />

              <Route exact path="/contact" component={ContactPage} />

              <Route exact path="/legal/:section" component={LegalPage} />

              <Route exact path="/blog" component={BlogsPage} />

              <Route exact path="/partnerships" component={PartnershipsPage} />
              {allCompanies
                .filter((company) => company.id !== "partnerships")
                .map((company, key) => (
                  <Route
                    exact
                    key={key}
                    path={`/company/${company.id}`}
                    render={(props) => (
                      <CompanyPage {...props} company={company} />
                    )}
                  />
                ))}
              {allCompaniesWithUnindexedCompanies.map((company, key) => (
                <Route
                  exact
                  key={key}
                  path={`/company/${company.id}`}
                  render={(props) => (
                    <CompanyPage {...props} company={company} />
                  )}
                />
              ))}
              {allBlogs.map((blog, key) => (
                <Route
                  exact
                  key={key}
                  path={`/blog/${blog.path}`}
                  render={(props) => blog.module}
                />
              ))}
              <Route component={NotFoundPage} />
            </Switch>

            <Footer
              bgColor="footer"
              size="normal"
              bgImage=""
              bgImageOpacity={1}
              description="A short description of what you do here"
              copyright={`© ${new Date().getFullYear()} Fairearth`}
              sticky={true}
            />
          </>
        </Router>
      </>
    </ThemeProvider>
  );
}

export default App;
