import React from "react";
import { Typography } from "@mui/material";
import { LogoFont } from "../../resources/constants";

function Kajal() {
  return (
    <>
      <Typography variant="h4" mb={2} mt={4} sx={{ fontFamily: LogoFont, fontWeight: "400" }}>
        What Makes Kajal New York Unique?
      </Typography>
      <Typography mb={4} variant="body1" sx={{ fontWeight: "normal" }}>
        Kajal goes the extra mile in each step of creating their tops. From regenerative farming to blockchain
        technology, from traceable packaging created from ocean-bound plastic pollution to sustainable spinning, they
        look at everything in an effort to push boundaries for a more positive and sustainable future.
      </Typography>
      <Typography variant="h4" mb={2} mt={2} sx={{ fontFamily: LogoFont, fontWeight: "400" }}>
        Top Values
      </Typography>
      <Typography variant="h5" mb={2} sx={{ fontWeight: "bold", color: "#304c42" }}>
        Women-Owned
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        As a woman-owned business, Kajal emphasizes the importance of supporting women's equality. The garments are
        produced in New York and India. In India, they work with Master G, at a woman-owned and operated factory that
        provides women from lower-income backgrounds with fair wage working opportunities.
      </Typography>
      <Typography variant="h5" mb={2} sx={{ fontWeight: "bold", color: "#304c42" }}>
        Small Scale Production
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        The company focuses on quality over quantity. They manufacture their garments in low-volume batches for quality
        control, aiming to avoid overproduction and prevent waste.
      </Typography>
      <Typography variant="h5" mb={2} sx={{ fontWeight: "bold", color: "#304c42" }}>
        Supply Chain Transparency
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        The founder, Kajal Goel, designs and sketches collections in New York. All materials are sourced from India and
        New York, chosen for their proximity to local manufacturers to reduce transport pollution. The collections are
        produced at Master G in India and the New York Studio. The latest collections are retailed through the eCommerce
        website. The company aims to create garments that last a long time, serving as a staple in the modern woman's
        wardrobe. They continuously strive to implement better ways to care for the planet and their clothes. Currently,
        the focus is on further end-of-life management that guarantees garment care, recyclability, and circularity.
      </Typography>
      <Typography variant="h5" mb={2} sx={{ fontWeight: "bold", color: "#304c42" }}>
        Zero Waste
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        In pursuit of a climate-neutral future, Kajal places importance on being zero waste. One method employed is the
        implementation of pattern-cutting techniques that effectively eliminate all waste during garment construction.
      </Typography>
      <Typography variant="h5" mb={2} sx={{ fontWeight: "bold", color: "#304c42" }}>
        Ecofriendly Materials
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        The company creates its garments using exclusively recycled, natural, and deadstock materials, devoid of
        chemical finishes. This approach significantly reduces pollution and mitigates excessive water consumption.
      </Typography>
      <img
        src="https://kajalnewyork.com/cdn/shop/files/Materials5_900x.png?v=1661966836"
        height={"auto"}
        alt={"kajal-deadstock"}
        width={"70%"}
      ></img>
    </>
  );
}

export default Kajal;
