import React from "react";
import Meta from "./../components/Meta";
import PartnershipsSection from "../components/PartnershipsSection";
import HeroSection from "../components/HeroSection";
import PartnershipsImage from "../assets/partnerships_page_image.jpg";

function PartnershipsPage(props) {
  return (
    <>
      <Meta title="Partnerships" />
      <HeroSection
        bgImage={PartnershipsImage}
        align={"center"}
        title={"Partnerships"}
      ></HeroSection>
      <PartnershipsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Contact Us"
        subtitle=""
        buttonText="Send message"
        buttonColor="primary"
        showNameField={true}
      />
    </>
  );
}

export default PartnershipsPage;
