import React from "react";
import { Typography } from "@mui/material";
import { LogoFont } from "../../resources/constants";
import GarmentSewing from "../../components/companies/images/ELK/garment_sewing.jpg";
import Garments from "../../components/companies/images/ELK/garments.png";
import GivingCampaign from "../../components/companies/images/ELK/giving_campaign.jpg";
import SmilingWorker from "../../components/companies/images/ELK/smiling_worker.jpg";

function ELK() {
  return (
    <>
      <Typography variant="h4" mb={2} mt={4} sx={{ fontFamily: LogoFont, fontWeight: "400" }}>
        What Makes ELK the Label Unique?
      </Typography>
      <Typography mb={4} variant="body1" sx={{ fontWeight: "normal" }}>
        ELK happens to be one of the few brands that focuses on sustainability and ethical consumption on all facets of
        its production making it a pioneer of independent, Australian design. It leads with a philosophy of conscious
        fashion, creativity, authentic design, contemporary fashion and independent, ethically made products.
      </Typography>
      <Typography variant="h4" mb={2} mt={2} sx={{ fontFamily: LogoFont, fontWeight: "400" }}>
        Top Values
      </Typography>
      <Typography variant="h5" mb={2} sx={{ fontWeight: "bold", color: "#304c42" }}>
        Employee Well Being
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        ELK is deeply committed to inclusivity, striving to create an inclusive work environment and promote diversity
        in their teams. ELK values equitable treatment, providing equal opportunities for growth and development
        regardless of factors such as gender, race, or age. They prioritize employee well-being, offering work
        flexibility and a family-friendly environment. In their product design, ELK embraces inclusivity by creating
        clothing that caters to a diverse range of body shapes and physical abilities. Their marketing campaigns feature
        diverse models, promoting body positivity and portraying a positive body image from various backgrounds and
        ages. Through these efforts, ELK fosters a culture of respect, collaboration, and equal opportunity, both within
        their organization and in their interactions with customers and the wider community.
      </Typography>
      <img src={SmilingWorker} height={"auto"} alt={"Worker smiling"} width={"70%"}></img>
      <Typography variant="h5" mb={2} sx={{ fontWeight: "bold", color: "#304c42" }}>
        Supply Chain Transparency
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        ELK ensures that their products are made with regard for workers, animals, and the environment. They have
        undertaken the task of mapping their entire supply chain and tracing their fibers and materials back to their
        origin. For example, ELK works with suppliers who have certifications such as SA8000, Fairtrade, and Fair Wear
        Foundation, demonstrating their commitment to fair labor practices. They also collaborate with suppliers who are
        part of the Leather Working Group (LWG), ensuring responsible leather sourcing. ELK actively supports and
        encourages their suppliers to improve their social and environmental practices. For instance, they work closely
        with suppliers to implement sustainable production techniques, reduce waste, and minimize the use of harmful
        chemicals. By prioritizing responsible sourcing, ELK ensures that their products are made ethically and
        sustainably, contributing to a more responsible and transparent fashion industry.
      </Typography>
      <img src={Garments} height={"auto"} alt={"Garments"} width={"70%"}></img>
      <Typography variant="h5" mb={2} sx={{ fontWeight: "bold", color: "#304c42" }}>
        Zero Waste
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        ELK is deeply committed to achieving zero waste in their business operations. They have set a goal to achieve
        zero waste to landfill by 2025. To accomplish this, ELK has implemented various strategies and initiatives. They
        prioritize reducing waste at the source by carefully planning their product buy and inventory levels to minimize
        deadstock. ELK also focuses on product longevity and repairability, encouraging customers to care for and repair
        their ELK products to extend their lifespan. They actively seek sustainable alternatives for packaging and
        printing materials, aiming for 100% reusable, recyclable, or compostable options by 2025. ELK is dedicated to
        finding innovative recycling solutions, such as fiber-to-fiber recycling, to divert waste from landfill. They
        also collaborate with partners to repurpose and upcycle waste materials. By embracing these initiatives, ELK is
        working towards a future where waste is minimized, resources are conserved, and a circular economy is fostered.
      </Typography>
      <img src={GarmentSewing} height={"auto"} alt={"Worker sewing"} width={"70%"}></img>
      <Typography variant="h5" mb={2} sx={{ fontWeight: "bold", color: "#304c42" }}>
        Sustainable Packaging
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        ELK is committed to sustainable packaging practices as part of its broader sustainability goals. The company
        aims to ensure that all ELK packaging is reusable, recyclable, or compostable. They prioritize the use of
        environmentally certified materials and prefer packaging that is either recyclable or compostable at the end of
        its life. ELK has set a target of using at least 50% recycled content across all its packaging and aims to have
        100% of virgin paper fibers certified by the Forest Stewardship Council (FSC). Additionally, ELK is actively
        phasing out problematic or unnecessary packaging, including single-use plastic packaging, and moving towards
        reuse models where possible. By prioritizing sustainable packaging, ELK is taking steps to reduce its
        environmental impact and contribute to a more circular economy.
      </Typography>
      <img src={GivingCampaign} height={"auto"} alt={"Sustainable Pakaging"} width={"70%"}></img>
    </>
  );
}

export default ELK;
