import React from 'react'
import BluesignLogo from './icons/Bluesign.svg'
import CertBCorpLogo from './icons/Certified_B_Corporation.svg'
import C2CLogo from './icons/Cradle_2_Cradle_Certified.svg'
import FairtradeLogo from './icons/Fairtrade.svg'
import FSCLogo from './icons/Forest_Stewardship_Council.svg'
import GlobalOrganicTextileStandardLogo from './icons/Global_Organic_Textile_Standard.svg'
import GRSLogo from './icons/Global_Recycled_Standard.svg'
import LWGLogo from './icons/Leather_Working_Group.svg'
import OekoTexLogo from './icons/OEKO_TEX.svg'
import SALogo from './icons/Soil_Association.svg'
import USDALogo from './icons/United_States_Department_of_Agriculture.svg'
import Certification from '../components/Certification';

export const Bluesign = () => (
    <Certification icon={BluesignLogo} text={"Bluesign certification signifies environmentally responsible textile and material production practices."}/>
)

export const CertBCorp = () => (
    <Certification icon={CertBCorpLogo} text={"Certified B ensures social and environmental responsibility in business practices."}/>
)

export const Cradle2Cradle = () => (
    <Certification icon={C2CLogo} text={"Cradle2Cradle emphasizes sustainable product design and circular economy principles."}/>
)

export const Fairtrade = () => (
    <Certification icon={FairtradeLogo} text={"Fairtrade ensures fair wages and ethical treatment for workers and farmers."}/>
)

export const FSC = () => (
    <Certification icon={FSCLogo} text={"Forest Stewardship Council promotes responsible forestry and wood product sourcing."}/>
)

export const GlobalOrganicTextileStandard = () => (
    <Certification icon={GlobalOrganicTextileStandardLogo} text={"Global Organic Textile Standard guarantees organic and eco-friendly textile production."}/>
)

export const GRS = () => (
    <Certification icon={GRSLogo} text={"Global Recycling Standard tracks and verifies recycled materials in product supply chains."}/>
)

export const LWG = () => (
    <Certification icon={LWGLogo} text={"Leather Working Group focuses on sustainable leather production and responsible sourcing."}/>
)

export const OekoTex = () => (
    <Certification icon={OekoTexLogo} text={"OEKO TEX certifies textiles free from harmful substances, promoting safety."}/>
)

export const SoilAssociation = () => (
    <Certification icon={SALogo} text={"Soil Association certifies organic and sustainable agricultural practices."}/>
)

export const USDA = () => (
    <Certification icon={USDALogo} text={"USDA validates organic food and agriculture standards in the United States."}/>
)
