import React from "react";
import {
  CardActionArea,
  Typography,
  CardContent,
  Card,
  Box,
} from "@mui/material";

function BlogCard(props) {
  const { blog } = props;
  return (
    <Box sx={{ width: "100%" }}>
      <Card elevation={0}>
        <CardActionArea href={`/blog/${blog.path}`}>
          <CardContent>
            <Typography gutterBottom variant="h2">
              {blog.name}
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: "normal" }}>
              Written on {blog.date}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              {blog.desc}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
}

export default BlogCard;
