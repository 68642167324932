import React from "react";
import { Container, Typography, Box, List, ListItem } from "@mui/material";
import Section from "./Section";
import { LogoFont } from "../resources/constants";

function PartnershipsSection(props) {
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            mx: "auto",
            mb: { lg: 16, sm: 8 },
            maxWidth: "sm",
            textAlign: "left",
          }}
        >
          <Typography
            variant="h4"
            component="h4"
            mb={2}
            fontFamily={LogoFont}
            fontWeight={"normal"}
            sx={{ fontWeight: "normal" }}
          >
            How can you submit to Fairearth?
          </Typography>
          <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
            If you'd like to create a profile for consumers who are aligned with
            your brand's values, you can either fill out our{" "}
            <a
              rel="noopener noreferrer"
              href="https://airtable.com/app7jXCb0P7rZx7Qg/shrqIMbDD9F4cZA93"
              target="_blank"
            >
              sign up form
            </a>
            {" "}or send me (Soumitri{" "}
            <span role="img" aria-label="leaf">
              🌿
            </span>
            ) an email at{" "}
            <a
              rel="noopener noreferrer"
              href="mailto:soumitri@fairearth.eco"
              target="_blank"
            >
              soumitri@fairearth.eco
            </a>
            .
          </Typography>
          <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
            Your email must include:
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                "& .MuiListItem-root": {
                  display: "list-item",
                },
              }}
            >
              <ListItem sx={{ p: 0 }}>Your first and last name</ListItem>
              <ListItem sx={{ p: 0 }}>The name of your company</ListItem>
              <ListItem sx={{ p: 0 }}>Your role at your company</ListItem>
            </List>
          </Typography>
          <Typography mb={4} variant="body1" sx={{ fontWeight: "normal" }}>
            I'll then get back to you about your profile creation!
          </Typography>
          <Typography
            variant="h4"
            component="h4"
            fontFamily={LogoFont}
            fontWeight={"normal"}
            mb={2}
            sx={{ fontWeight: "normal" }}
          >
            Why should you create a profile?
          </Typography>
          <Typography mb={4} variant="body1" sx={{ fontWeight: "normal" }}>
            Fairearth provides a way for your brand to share its unique culture
            with consumers looking for more than just a piece of clothing.
            Consumers are often bombarded with ads from various brands, but with
            no information about the values of the brand, the practices these
            brands engage in, and no idea if their personal values are aligned
            with those of the brand's. In addition to getting in front of
            hundreds of consumers searching for a brand like yours, creating a
            profile will help you articulate what values define your brand and
            evaluate how well these values are translating into your day-to-day.
            Lastly, having a profile will make consumers more likely to buy from
            your brand because they have already identified that the values of
            the brand resonate with them.
          </Typography>
          <Typography
            variant="h4"
            component="h4"
            mb={2}
            fontFamily={LogoFont}
            fontWeight={"normal"}
            sx={{ fontWeight: "normal" }}
          >
            Is my brand eligible to have a profile?
          </Typography>
          <Typography mb={0} variant="body1" sx={{ fontWeight: "normal" }}>
            Your brand is eligible for a profile if you sell clothing to the
            general public and have a website to direct consumers to. It doesn't
            matter if you're a local brand working out of your garage or if
            you've raised money. You can be a solo operation or a multinational
            with lots of employees.
            <br />
            <br />
            As long as you're excited about sharing your brand's values, you can
            create a profile!
          </Typography>
        </Box>
      </Container>
    </Section>
  );
}

export default PartnershipsSection;
