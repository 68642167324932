import React from "react";
import Meta from "./../components/Meta";
import Section from "../components/Section";
import CompanyHeadlineSection from "../components/CompanyHeadlineSection";
import CompanyMain from "../components/CompanyMain";
import CompanySidebar from "../components/CompanySidebar";
import { Grid, useTheme, useMediaQuery } from "@mui/material";
import CompressedCompanyPage from "../components/CompressedCompanyPage";

function CompanyPage(props) {
  const { company } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Meta title={company.name} description={`Learn about ${company.name}`} />
      <Section bgColor="default" size="auto">
        {isMobile ? (
          <CompressedCompanyPage company={company}></CompressedCompanyPage>
        ) : (
          <>
            <CompanyHeadlineSection company={company} />
            <Grid container>
              <CompanySidebar
                title={company.name}
                description={company.description}
                website={company.website}
                certifications={company.certifications}
                clothing={company.clothing}
                shipping={company.shipping}
                filters={company.filters}
                location={company.location}
                cost={company.cost}
              />
              <CompanyMain
                content={company.content}
                description={company.longDescription}
              />
            </Grid>
          </>
        )}
      </Section>
    </>
  );
}

export default CompanyPage;
