import React from "react";
import Fision from "../components/companies/Fision";
import fisionShirt from "../components/companies/images/fision/fision-shirt.jpg";
import fisionLogo from "../components/companies/images/fision/fision-logo.png";
import Kajal from "../components/companies/Kajal";
import Franc from "../components/companies/Franc";
import Londre from "../components/companies/Londre";
import OutlandDenim from "../components/companies/OutlandDenim";
import KajalLogo from "../components/companies/images/kajal/kajal_logo.png";
import FrancLogo from "../components/companies/images/franc/franc-logo.png";
import LondreLogo from "../components/companies/images/londre/londre-logo.png";
import LondreHeadlineImg from "../components/companies/images/londre/londre-swimsuits-back.jpg";
import OutlandDenimLogo from "../components/companies/images/outlanddenim/outland-denim-logo.png";
import OutlandDenimHeadlineImg from "../components/companies/images/outlanddenim/outland-denim-pants.png";
import ELKLogo from "../components/companies/images/ELK/ELK_Logo.svg";
import ELKHeadlineImg from "../components/companies/images/ELK/headline_image.png";
import {
  planetFilters,
  materialsFilters,
  communityFilters,
  clothingFilters,
  promotionFilters,
  labourFilters,
  certificationFilters,
} from "./filters";
import {
  Bluesign,
  CertBCorp,
  // Cradle2Cradle,
  // FSC,
  // Fairtrade,
  // GRS,
  GlobalOrganicTextileStandard,
  // LWG,
  OekoTex,
  // SoilAssociation,
  // USDA
} from "./icons";
import ELK from "../components/companies/ELK";

export const allCompanies = [
  {
    name: "The Fision",
    id: "fision",
    filters: [
      planetFilters.MadeInUSA,
      planetFilters.RecycledPackaging,
      communityFilters.SupplyChainTransparency,
      communityFilters.EmployeeWellbeing,
      communityFilters.Philanthropy,
      clothingFilters.Tops,
      clothingFilters.Women,
      clothingFilters.Men,
      materialsFilters.Recycled,
      materialsFilters.Plant,
      materialsFilters.Regenerative,
    ],
    website: { nick: "The Fision", url: "https://thefision.com/" },
    certifications: [],
    description: [
      <b>The Fision</b>,
      " provides T-shirts with a purpose, that you’ll not only look good in, but equally important, feel great about wearing!",
    ],
    longDescription: `From dirt to shirt, The Fision is a radically transparent and 100%
    traceable t-shirt company utilizing climate-positive Good Earth Cotton®.
    Leveraging cutting edge technologies like DNA tracers embedded into raw
    materials, The company is reimagining the industry’s value chain by
    literally sewing transparency into its supply chain to create a new
    industry standard. They are committed to communicating the truth about their
    products while exposing the environmental and societal impacts of the
    fashion industry as a whole. The Fision offers custom t-shirts and sweatshirts so
    businesses can provide a sustainable and effective way to promote
    themselves or create unique and memorable gifts.`,
    content: <Fision />,
    shipping: ["Worldwide"],
    headlineImage1: fisionShirt,
    logo: fisionLogo,
    location: "USA",
    cost: 3,
  },
  {
    name: "Kajal New York",
    id: "kajalnewyork",
    filters: [
      materialsFilters.Recycled,
      materialsFilters.OrganicCotton,
      materialsFilters.Deadstock,
      materialsFilters.BananaCrepe,
      materialsFilters.CactusLeather,
      materialsFilters.PYRATEX,
      labourFilters.WomenOwned,
      labourFilters.SmallScaleProduction,
      communityFilters.SupplyChainTransparency,
      planetFilters.ZeroWaste,
      planetFilters.EcofriendlyMaterials,
      planetFilters.MadeInAsia,
      labourFilters.FairWages,
      clothingFilters.Women,
      clothingFilters.Tops,
      clothingFilters.Bottoms,
      clothingFilters.Outerwear,
      clothingFilters.Accessories,
    ],
    website: { nick: "Kajal New York", url: "https://kajalnewyork.com/" },
    certifications: [],
    description: [
      <b>Kajal New York</b>,
      "  is focused on offering wearable, futuristic, sustainably made and high quality luxury garments.",
    ],
    longDescription: `Kajal New York is focused on offering wearable, futuristic, sustainably made and high quality luxury garments.`,
    content: <Kajal />,
    shipping: ["Worldwide"],
    headlineImage1: "https://kajalnewyork.com/cdn/shop/files/00-2_540x.jpg?v=1627228762",
    logo: KajalLogo,
    location: "USA",
    cost: 4,
  },
  {
    name: "FRANC",
    id: "franc",
    filters: [
      materialsFilters.Recycled,
      materialsFilters.OrganicCotton,
      materialsFilters.Tencel,
      communityFilters.SupplyChainTransparency,
      communityFilters.EmployeeWellbeing,
      labourFilters.FairWages,
      labourFilters.WomenOwned,
      planetFilters.MadeInCanada,
      planetFilters.CarbonNeutrality,
      planetFilters.RecyclablePackaging,
      clothingFilters.Women,
      clothingFilters.Men,
      clothingFilters.Tops,
      clothingFilters.Bottoms,
      clothingFilters.Dresses,
      clothingFilters.Accessories,
      certificationFilters.BCorp,
      certificationFilters.Bluesign,
      certificationFilters.GlobalOrganicTextileStandard,
    ],
    website: { nick: "Franc", url: "https://wearfranc.com" },
    certifications: [<CertBCorp />, <Bluesign />, <GlobalOrganicTextileStandard />],
    description: [
      <b>FRANC</b>,
      "  is a certified B Corporation™ and believes in honesty when it comes to their clothing production.",
    ],
    longDescription: `FRANC is a certified B Corporation™ and believes in honesty when it comes to their clothing production.`,
    content: <Franc />,
    shipping: ["Canada / USA / Australia / New Zealand / Europe"],
    headlineImage1:
      "https://wearfranc.com/cdn/shop/files/CropSweat_HghRseJoggerNicoleBreanne-FrancFebruary-CasaBona-258.jpg?v=1680900616&width=1950",
    logo: FrancLogo,
    location: "Canada",
    cost: 4,
  },
  {
    name: "LONDRE",
    id: "londre",
    filters: [
      materialsFilters.Recycled,
      materialsFilters.Plastics,
      materialsFilters.Bamboo,
      labourFilters.SmallScaleProduction,
      labourFilters.FairWages,
      labourFilters.WomenOwned,
      planetFilters.RecyclablePackaging,
      planetFilters.MadeInCanada,
      clothingFilters.Women,
      clothingFilters.Swimwear,
      clothingFilters.Dresses,
      clothingFilters.Accessories,
      certificationFilters.OEKOTEX,
    ],
    website: { nick: "Londre", url: "https://londrebodywear.com" },
    certifications: [<OekoTex />],
    description: [
      <b>LONDRE</b>,
      " creates the most flattering and high quality swimwear with the lowest impact on the planet.",
    ],
    longDescription: `LONDRE creates the most flattering and high quality swimwear with the lowest impact on the planet.`,
    content: <Londre />,
    shipping: ["Worldwide"],
    headlineImage1: LondreHeadlineImg,
    logo: LondreLogo,
    location: "Canada",
    cost: 3,
  },
  {
    name: "Outland Denim",
    id: "outlanddenim",
    filters: [
      materialsFilters.Recycled,
      materialsFilters.OrganicCotton,
      communityFilters.SupplyChainTransparency,
      labourFilters.ProductionFacilitiesOwnership,
      labourFilters.FairWages,
      planetFilters.EcofriendlyMaterials,
      communityFilters.EmployeeWellbeing,
      clothingFilters.Women,
      clothingFilters.Men,
      clothingFilters.Tops,
      clothingFilters.Bottoms,
      certificationFilters.BCorp,
      certificationFilters.GlobalOrganicTextileStandard,
    ],
    website: { nick: "Outland Denim", url: "https://www.outlanddenim.com" },
    certifications: [<CertBCorp />, <GlobalOrganicTextileStandard />],
    description: [
      <b>Outland Denim</b>,
      "  is an Australian denim brand that takes a human-centred approach in their values and practices.",
    ],
    longDescription: `Outland Denim is an Australian denim brand that takes a human-centred approach in their values and practices.`,
    content: <OutlandDenim />,
    shipping: ["Canada / USA / Australia / New Zealand / Europe"],
    headlineImage1: OutlandDenimHeadlineImg,
    logo: OutlandDenimLogo,
    location: "Australia",
    cost: 4,
  },
  {
    name: "ELK The Label",
    id: "elk",
    filters: [
      materialsFilters.OrganicCotton,
      materialsFilters.Wool,
      materialsFilters.Leather,
      communityFilters.EmployeeWellbeing,
      communityFilters.SupplyChainTransparency,
      planetFilters.ZeroWaste,
      planetFilters.SustainablePackaging,
      clothingFilters.Women,
      clothingFilters.Men,
      clothingFilters.Tops,
      clothingFilters.Bottoms,
      clothingFilters.Accessories,
      clothingFilters.Footwear,
      labourFilters.FamilyOwned,
    ],
    website: { nick: "ELK The Label", url: "https://us.elkthelabel.com" },
    certifications: [],
    description: [
      <b>ELK The Label</b>,
      "  is a stalwart of independent, Australian design and is entirely focused on conscious fashion, quality, and design.",
    ],
    longDescription: `ELK The Label is a stalwart of independent, Australian design and is entirely focused on conscious fashion, quality, and design.`,
    content: <ELK />,
    shipping: ["Worldwide"],
    headlineImage1: ELKHeadlineImg,
    logo: ELKLogo,
    location: "Australia",
    cost: 2,
  },
  {
    name: "Fairearth Partnerships",
    id: "partnerships",
    filters: [promotionFilters.Exposure, promotionFilters.ShareBrandValues, promotionFilters.MakesBrandUnique],
    description: [
      <span>
        <b>Want to see your brand?</b>
        <br />
      </span>,
      " Create a profile with us today!",
      <br />,
      <br />,
      <br />,
      <b>Fairearth</b>,
      " wants to onboard more brands! Connecting with us allows consumers to learn more about your brand.",
    ],
  },
];

export const allCompaniesWithUnindexedCompanies = [];
