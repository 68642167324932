import React from "react";
import { Typography } from "@mui/material";
import { LogoFont } from "../../resources/constants";

function Franc() {
  return (
    <>
      <Typography
        variant="h4"
        mb={2}
        mt={4}
        sx={{ fontFamily: LogoFont, fontWeight: "400" }}
      >
        What Makes Franc Unique?
      </Typography>
      <Typography mb={4} variant="body1" sx={{ fontWeight: "normal" }}>
        Franc's essentials are made from sustainable materials, allowing you to
        strut your stuff all day, every day, while knowing you're making a
        difference. Franc believes that personal style is what truly sets
        individuals apart, not the latest trend. They provide timeless basics
        and focus on getting their process right, as evidenced by their multiple
        certifications. Franc also approaches sizing and fit differently helping
        customers get their ideal fit.
      </Typography>
      <Typography
        variant="h4"
        mb={2}
        mt={2}
        sx={{ fontFamily: LogoFont, fontWeight: "400" }}
      >
        Top Values
      </Typography>
      <Typography
        variant="h5"
        mb={2}
        sx={{ fontWeight: "bold", color: "#304c42" }}
      >
        Supply Chain Transparency
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        Franc sources its yarn materials from various locations, with TENCEL™
        coming from Austria, Cotton from the United States, and GOTS Organic
        Cotton from Kahramanmaras, Turkey. They use Bluesign® certified dyes for
        their fabrics, ensuring the highest environmental standards for
        textiles. Additionally, the dye house that finishes and dyes their
        custom fabrics collaborates with Index Energy, which supplies renewable
        energy for the facility. The dye house also utilizes filtered waste from
        dye production to generate additional renewable energy, operating in a
        cyclical system to maintain a minimal carbon footprint.
      </Typography>
      <img
        src="https://wearfranc.com/cdn/shop/files/outfabric.jpg?v=1613562267&width=900"
        height={"auto"}
        alt={"materials"}
        width={"70%"}
      ></img>
      <Typography
        variant="h5"
        mb={2}
        sx={{ fontWeight: "bold", color: "#304c42" }}
      >
        Employee Well-Being
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        Franc's clothing is cut and sewn just outside of Toronto. They
        collaborate with two factory partners, seeing them as an extension of
        their brand. These factories are situated approximately 30 km from
        Franc's location, allowing for easy accessibility. The close proximity
        also contributes to a notably low carbon footprint. Franc prioritizes
        fair treatment and wages for the workers who make their clothes, making
        regular visits to both factories to ensure compliance with Ontario
        employment standards and the well-being of the workers.{" "}
      </Typography>
      <img
        src="https://wearfranc.com/cdn/shop/files/outfactory_34fa0f43-594a-40f7-802c-f605e1e60600.jpg?v=1649796133&width=900"
        height={"auto"}
        alt={"materials"}
        width={"70%"}
      ></img>
      <Typography
        variant="h5"
        mb={2}
        sx={{ fontWeight: "bold", color: "#304c42" }}
      >
        Fair Wages
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        Franc's factories, which serve as their business partners, are not owned
        or operated by the company but are considered an extension of Franc.
        Their primary factory, of medium size, employs 48 people, and they
        collaborate with a secondary factory, smaller in scale, with
        approximately 20 full-time workers. New hires lacking experience start
        at $15 per hour for the first 3 months as they receive on-the-job
        training, while cutters and sewers with experience earn $19 per hour or
        more (last updated in 2021).
      </Typography>
      <Typography
        variant="h5"
        mb={2}
        sx={{ fontWeight: "bold", color: "#304c42" }}
      >
        Women-Owned
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        Franc was founded by Brandy Mercredi, a veteran apparel designer and
        product developer who, much like its customers, was dissatisfied with
        the conventional practices of the fashion industry. Brandy Mercredi
        decided to utilize her skills and experience to establish a company
        dedicated to making a positive impact on both people and the planet.
        Franc's commitment extends beyond sustainability. They donate all their
        seconds, samples, and overstock items to the YWCA Toronto. While
        striving to minimize sample and overstock items, they prioritize giving
        these new or like-new items to women in need instead of hosting multiple
        sample sales a year.
      </Typography>
      <img
        src="https://wearfranc.com/cdn/shop/files/supporting.jpg?v=1613562268&width=900"
        height={"auto"}
        alt={"materials"}
        width={"70%"}
      ></img>
      <Typography
        variant="h5"
        mb={2}
        sx={{ fontWeight: "bold", color: "#304c42" }}
      >
        Carbon Neutrality
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        Franc calculates carbon emissions for each order placed and uses the
        totals to acquire carbon removal credits. These credits help neutralize
        shipping emissions and provide support for various initiatives. Their
        primary focus, even without credits, is on supporting innovative
        technologies that effectively remove carbon for a minimum of 1,000 years
      </Typography>
      <img
        src="https://wearfranc.com/cdn/shop/files/concept-to-outfit_production.jpg?crop=center&height=378&v=1613527986&width=540
        "
        height={"auto"}
        alt={"materials"}
        width={"70%"}
      ></img>
      <Typography
        variant="h5"
        mb={2}
        sx={{ fontWeight: "bold", color: "#304c42" }}
      >
        Recyclable Packaging
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        Franc is committed to being 100% plastic-free. Their shipping bags are
        sturdy, 100% compostable, and recyclable, and they use marketing
        materials, including hangtags, printed on 100% recycled or FSC-certified
        paper. Even if they use clear sleeves for shipping or storage, these are
        also 100% compostable and reusable for garbage or compost. Franc's
        dedication to being plastic-free is part of their efforts to minimize
        the environmental impact and keep customers' carbon footprints as low as
        possible.
      </Typography>
      <img
        src="https://wearfranc.com/cdn/shop/files/ourpackaging2.jpg?v=1646937338&width=900"
        height={"auto"}
        alt={"materials"}
        width={"70%"}
      ></img>
    </>
  );
}

export default Franc;
