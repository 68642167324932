import React from "react";
import { Box, Link, Typography, Grid } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { LogoFont } from "../resources/constants";
import greenLeaf from "../assets/illustration/2023.9.6-illustration_green_leaf_small.svg";
import CompanyHeadlineSection from "./CompanyHeadlineSection";
import { clothingFilters, materialsFilters, certificationFilters } from "../resources/filters";

function CompressedCompanyPage(props) {
  const { company } = props;

  return (
    <>
      <CompanyHeadlineSection company={company} />
      <Typography variant="h1" pl={3} sx={{ fontFamily: LogoFont }}>
        {company.name}
      </Typography>
      <Grid sm={12} md={4} pl={{ lg: 5, md: 3 }} pt={4}>
        <Box pl={3} pb={1} pt={2} sx={{ bgcolor: "#F7F5F3" }}>
          <Typography variant="body1" sx={{ fontWeight: "normal" }}>
            <strong>Website: </strong>
            <Link
              variant="body1"
              target="_blank"
              component="a"
              href={company.website.url}
              sx={{ color: "#000", fontWeight: "normal" }}
            >
              Visit {company.website.nick}
              <OpenInNewIcon sx={{ my: "-5px" }}></OpenInNewIcon>
            </Link>
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ mt: 1, mb: 1, fontWeight: "normal" }}
          >
            <strong>Shipping: </strong>
            {company.shipping.join(" \\ ")}
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ mb: 1, fontWeight: "normal" }}
          >
            <strong>Location: </strong> {company.location}
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ mb: 1, fontWeight: "normal" }}
          >
            <strong>Price: </strong>
            {Array.from({ length: company.cost }, (_, i) => (
              <AttachMoneyIcon
                fontSize="small"
                key={i}
                sx={{ mx: "-4px", my: "-5px" }}
              ></AttachMoneyIcon>
            ))}
            {Array.from({ length: 4 - company.cost }, (_, i) => (
              <AttachMoneyIcon
                fontSize="small"
                key={i}
                sx={{ mx: "-4px", my: "-5px", color: "grey" }}
              ></AttachMoneyIcon>
            ))}
          </Typography>
        </Box>
        <Box px={3} pt={2}>
          <Typography mb={0} variant="body1" sx={{ fontWeight: "500" }}>
            {company.longDescription}
          </Typography>
          {company.content}
        </Box>
        <Box pl={3} mt={4} pb={3} pt={1} sx={{ bgcolor: "#F7F5F3" }}>
          <Typography
            variant="h3"
            mt={2}
            gutterBottom
            sx={{ fontFamily: LogoFont, fontWeight: "normal" }}
          >
            Values
          </Typography>
          <ul
            style={{
              paddingLeft: 0,
              listStyle: "none",
            }}
          >
            {company.filters
              .filter(
                (item) =>
                  !Object.values(clothingFilters).includes(item) &&
                  !Object.values(materialsFilters).includes(item) && 
                  !Object.values(certificationFilters).includes(item)
              )
              .map((item, index) => (
                <li key={index} style={{ fontWeight: "500" }}>
                  <img
                    src={greenLeaf}
                    alt={"bullet point"}
                    width={"20"}
                    height={"20"}
                  />
                  {item}
                </li>
              ))}
          </ul>
          <Typography
            variant="h3"
            mt={5}
            gutterBottom
            sx={{ fontFamily: LogoFont, fontWeight: "normal" }}
          >
            Clothing
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "500" }}>
            {company.filters
              .filter((item) => Object.values(clothingFilters).includes(item))
              .join(" \\ ")}
          </Typography>
          <Typography
            variant="h3"
            mt={5}
            gutterBottom
            sx={{ fontFamily: LogoFont, fontWeight: "normal" }}
          >
            Materials
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "500" }}>
            {company.filters
              .filter((item) => Object.values(materialsFilters).includes(item))
              .join(" \\ ")}
          </Typography>
          {company.certifications.length > 0 && (
        <>
          <Typography
            variant="h4"
            mt={6}
            gutterBottom
            sx={{ fontFamily: LogoFont, fontWeight: "normal" }}
          >
            Certifications
          </Typography>
          {company.certifications.map((certification, key) => (
            // react.fragment is only here to remove a key error warning
            <React.Fragment key={key}>{certification}</React.Fragment>
          ))}
        </>
      )}
        </Box>
      </Grid>
    </>
  );
}

export default CompressedCompanyPage;
