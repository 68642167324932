import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Avatar,
  Grid,
  Fade,
  TextField,
  Autocomplete,
} from "@mui/material";
import { allCompanies } from "../resources/companies";
import SearchIcon from "@mui/icons-material/Search";

let companyOptions = allCompanies.map((company) => company.name);

const useStyles = makeStyles((theme) => ({
  input: {
    "&::placeholder": {
      textAlign: "left",
      fontWeight: "500",
      color: "white",
    },
    fontWeight: "500",
    color: "white",
  },
  avatar: {
    backgroundColor: "#94a19b",
    color: "#fff",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#304c42",
      cursor: "pointer",
    },
  },
}));

function SearchBar(props) {
  const { setQuery } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);

  const handleAvatarClick = () => {
    setIsActive(true);
  };

  const handleInputBlur = () => {
    setIsActive(false);
  };

  return (
    <Grid item sx={{ marginLeft: "auto" }}>
      {isActive ? (
        <Fade in={isActive} timeout={1200}>
          <Autocomplete
            freeSolo
            id="search-bar"
            options={companyOptions}
            onChange={(_, query) => {
              setQuery(query);
            }}
            onInputChange={(_, value) => {
              if (value.length === 0) {
                if (open) setOpen(false);
              } else {
                if (!open) setOpen(true);
              }
            }}
            sx={{
              width: 200,
              "& .MuiAutocomplete-endAdornment .MuiIconButton-root": {
                color: "white",
              },
            }}
            open={open}
            onClose={() => setOpen(false)}
            renderInput={(params) => (
              <TextField
                variant="standard"
                fullWidth
                autoFocus
                sx={{
                  textDecoration: "None",
                  backgroundColor: "#304C42",
                  borderRadius: "20px",
                  padding: "2px",
                  paddingLeft: "20px",
                }}
                {...params}
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                  ...params.InputProps,
                  disableUnderline: true,
                }}
                onBlur={handleInputBlur}
                placeholder="Search Brands"
              />
            )}
          />
        </Fade>
      ) : (
        <Avatar className={classes.avatar} onClick={handleAvatarClick}>
          <SearchIcon />
        </Avatar>
      )}
    </Grid>
  );
}

export default SearchBar;
