import React from "react";
import { Typography } from "@mui/material";
import { LogoFont } from "../../resources/constants";
import LondreImg from "../companies/images/londre/londre-swimsuit.jpg";

function Londre() {
  return (
    <>
      <Typography variant="h4" mb={2} mt={4} sx={{ fontFamily: LogoFont, fontWeight: "400" }}>
        What Makes Londre Unique?
      </Typography>
      <Typography mb={4} variant="body1" sx={{ fontWeight: "normal" }}>
        Londre is a sustainable swimwear company known for its eco-friendly practices, including the use of plastic
        water bottles and ethical production in Canada. Their minimalist designs, commitment to plastic-free packaging,
        and support for environmental initiatives make them unique in the industry, emphasizing sustainability and
        transparency.
      </Typography>
      <Typography variant="h4" mb={2} mt={2} sx={{ fontFamily: LogoFont, fontWeight: "400" }}>
        Top Values
      </Typography>
      <Typography variant="h5" mb={2} sx={{ fontWeight: "bold", color: "#304c42" }}>
        Small Scale Production
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        The workshop focuses on small production to minimize waste.
      </Typography>
      {/* <img src={LondreImg} height={"auto"} alt={"materials"} width={"70%"}></img> */}
      <Typography variant="h5" mb={2} sx={{ fontWeight: "bold", color: "#304c42" }}>
        Fair Wages
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        Londre takes care of its people - all factories are based in Vancouver, Canada and their sewers are paid well
        above a living wage.
      </Typography>
      <Typography variant="h5" mb={2} sx={{ fontWeight: "bold", color: "#304c42" }}>
        Women-Owned
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        The owners, Ainsley and Hannah, were inspired to create the most flattering, high quality garments with the
        lowest possible impact while working on a photo shoot in Mexico. They want to use their small business to make a
        quantifiable change for women and the environment.
      </Typography>
      <Typography variant="h5" mb={2} sx={{ fontWeight: "bold", color: "#304c42" }}>
        Recyclable Packaging
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        Londre's packaging is compostable (made from corn) and recyclable.
      </Typography>
    </>
  );
}

export default Londre;
