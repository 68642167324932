import React from "react";
import { Container, Typography, Box } from "@mui/material";
import Meta from "../Meta";

function WelcomeToFairearthBlogPost(props) {
  const { date, title } = props;
  return (
    <>
      <Meta title="Welcome to Fairearth" description="Welcome to Fairearth" />
      <Container maxWidth="md">
        <Box
          sx={{
            mx: "auto",
            mt: { lg: 5, sm: 8 },
            mb: { lg: 16, sm: 8 },
            maxWidth: "sm",
            textAlign: "left",
          }}
        >
          <Typography
            variant="h4"
            component="h4"
            mb={4}
            sx={{ fontWeight: "normal" }}
          >
            {title}
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            mb={4}
            sx={{ fontWeight: "normal" }}
          >
            Written {date}
          </Typography>
          <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
            Welcome to Fairearth, a platform dedicated to conscious consumerism.
            Starting Fairearth is a particularly exciting moment for me. I have thought about this project a lot and to finally bring it to life is a particularly exciting moment for me. My sincere hope is that through Fairearth, the average consumer has more insight into the values espoused by the brands they support.
          </Typography>
          <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
            At the heart of Fairearth is our love for the people and the environment around us and the desire to care for that. I've often grappled with climate defeatism - the sentiment that if the world is already doomed, then why bother at all? So what if we
            saved on that roll of toilet paper? or on that piece of cardboard? A
            lot of times, I find tremendous value in that cynicism. Other times,
            I'd like to erase that part of myself forever!
          </Typography>
          <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
            In a world so devoid of inherent meaning, pondering the significance
            of our actions is only natural. I found my answer in the word,
            'Dharma'. Dharma is a concept in Hindu literature, that roughly
            translates to one's duty. The idea behind doing one's dharma is to
            focus on fulfilling one's duty with no care for the fruit of one's
            labour - let the chips fall where they may, as long as you have done
            your part.
          </Typography>
          <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
            For me, being mindful of what I consume is my dharma. I feel only
            gratitude to be able to start Fairearth and to provide an avenue for
            others to be more mindful about their consumption habits. I
            encourage you to do the same, to care for the planet, not in the
            hopes of saving it, but simply for the sake of doing what resonates
            most with you.
          </Typography>
        </Box>
      </Container>
    </>
  );
}

export default WelcomeToFairearthBlogPost;
