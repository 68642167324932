import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Avatar,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Box,
  Grid,
} from "@mui/material";
import greenLeaf from "../assets/illustration/2023.9.6-illustration_green_leaf_small.svg";
import { clothingFilters, materialsFilters } from "../resources/filters";
import { LogoFont } from "../resources/constants";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 75,
    height: 75,
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    transition: "opacity .5s ease-in-out",
    opacity: 0,
  },
  logoVisible: {
    opacity: 1,
  },
  cardContent: {
    position: "absolute",
    backgroundColor: "#f7f5f3",
    padding: "40px 16px 80px 24px",
    zIndex: 1,
    "&:last-child": { pb: 0 },
  },
  listImage: {
    width: 20,
    height: 20,
  },
  learnMoreWrapper: {
    position: "absolute",
    width: 150,
    right: 0,
    bottom: 0,
    transition: "opacity 0.5s ease-in-out",
    opacity: 0,
  },
  learnMore: {
    backgroundColor: "#304C42",
    fontWeight: "bold",
    color: "#fff",
    paddingTop: 6,
    paddingBottom: 6,
    borderTopLeftRadius: 39,
    textAlign: "center",
  },
  learnMoreVisible: {
    opacity: 1,
    zIndex: 1,
  },
}));

function CompanyCard(props) {
  const classes = useStyles();

  const { company } = props;
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleHoverLeave = () => {
    setIsHovered(false);
  };

  const url =
    company.id === "partnerships" ? "/partnerships" : `/company/${company.id}`;

  const companyName = company.id !== "partnerships" ? company.name : "";

  return (
    <>
      <Grid item={true} xs={12} sm={4} key={company.id}>
        <Card
          onMouseEnter={handleHover}
          onMouseLeave={handleHoverLeave}
          elevation={0}
          square={true}
          sx={{
            width: 300,
            height: 300,
            position: "relative",
            overflow: "hidden",
            borderRadius: isHovered ? "13%" : "0%",
            // border: isHovered && "5px solid #f7f5f3", TODO: look into hiding the corner border better
            transition: "border-radius 0.2s ease-in-out",
          }}
        >
          <CardActionArea href={url}>
            <CardMedia
              component="img"
              height="180"
              image={company.headlineImage1}
              alt={companyName}
              sx={{ backgroundColor: "#304C42" }}
            />
            <Avatar
              alt="Circular Avatar"
              sx={{
                zIndex: isHovered ? 0 : 2,
              }}
              className={`${classes.logo} ${
                isHovered ? "" : classes.logoVisible
              }`}
            >
              {company.logo ? (
                <img
                  src={company.logo}
                  alt="Company Logo"
                  style={{
                    width: "80%",
                    height: "80%",
                    objectFit: "contain", // Maintain the aspect ratio and fit inside
                  }}
                />
              ) : (
                <Typography
                  variant="h3"
                  sx={{
                    fontFamily: LogoFont,
                    fontWeight: "500",
                    color: "black",
                    // TODO: Center the icon more horizontally within the avatar component
                  }}
                >
                  ?
                </Typography>
              )}
            </Avatar>
            <CardContent
              className={classes.cardContent}
              sx={{
                transform: isHovered
                  ? company.id === "partnerships"
                    ? "translateY(-70%)"
                    : "translateY(-55%)"
                  : "inherit",
                transition: "transform .2s ease-in-out",
              }}
            >
              <Typography variant="subtitle2">{company.description}</Typography>
              <Typography variant="subtitle2" mb={4}>
                <ul
                  style={{
                    paddingLeft: 0,
                    listStyle: "none",
                  }}
                >
                  {company.filters
                    .filter(
                      (item) =>
                        !Object.values(clothingFilters).includes(item) &&
                        !Object.values(materialsFilters).includes(item)
                    )
                    .slice(0, 5)
                    .map((item, index) => (
                      <li key={index} style={{ fontWeight: "500" }}>
                        <img
                          src={greenLeaf}
                          alt={"bullet point"}
                          width={"20"}
                          height={"20"}
                        />
                        {item}
                      </li>
                    ))}
                </ul>
              </Typography>
            </CardContent>
          </CardActionArea>
          {/* TODO: The Learn More text below should be in the CardActionArea,
          but the area is only between the top and bottom of the image.
          It should be the entire card. */}
          <div
            className={`${classes.learnMoreWrapper} ${
              isHovered ? classes.learnMoreVisible : ""
            }`}
          >
            <Box className={classes.learnMore}>Learn More...</Box>
          </div>
        </Card>
      </Grid>
    </>
  );
}

export default CompanyCard;
