import React from "react";
import { Typography } from "@mui/material";
import { LogoFont } from "../../resources/constants";
import JeansMeasuring from "../../components/companies/images/outlanddenim/outland_denim_jeans_measuring.png";
import TextileFactory from "../../components/companies/images/outlanddenim/textile_factory.png";
import SewingMachine from "../../components/companies/images/outlanddenim/sewing machine.png";
import WasteMaterials from "../../components/companies/images/outlanddenim/waste_materials.png";


function OutlandDenim() {
  return (
    <>
      <Typography variant="h4" mb={2} mt={4} sx={{ fontFamily: LogoFont, fontWeight: "400" }}>
        What Makes Outland Denim Unique?
      </Typography>
      <Typography mb={4} variant="body1" sx={{ fontWeight: "normal" }}>
        Outland Denim prides itself on its commitment to transparency and to the ideas of human empowerment and
        environmental sustainability. They are also unique in that they garments are made in our very own production and
        finishing facilities, which allows us a degree of operational agility, security and traceability above those
        brands who outsource their manufacturing. Outland Denim is the first Australian and second denim brand globally
        to receive its B Corporation™ certification. Every step of their production cycle is documented with precise
        details for their consumers to view in their annual reports.
      </Typography>
      <Typography variant="h4" mb={2} mt={2} sx={{ fontFamily: LogoFont, fontWeight: "400" }}>
        Top Values
      </Typography>
      <Typography variant="h5" mb={2} sx={{ fontWeight: "bold", color: "#304c42" }}>
        Supply Chain Transparency
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        Outland Denim demonstrates a strong commitment to supply chain transparency in their operations. This commitment
        is evident in their practices such as disclosing the complete journey of their denim supply chain, from
        production to cotton seed. They emphasize ethical sourcing of raw materials, including organic cotton, and
        collaborate with globally recognized certification bodies to ensure the highest standards. For instance, they
        require their denim supplier in Turkey to follow the Trade Union (Türk-İş) Estimation for Living Wages and
        provide detailed insight into their wage structure. Additionally, their fabric supplier in India is established
        as a social enterprise and conducts living wage benchmarks in collaboration with the Global Living Wage
        Coalition. These practices underscore Outland Denim's dedication to transparency, ethical sourcing, and ensuring
        fair and sustainable practices throughout their supply chain.
      </Typography>
      <img
        src={WasteMaterials}
        height={"auto"}
        alt={"clothing inspection in factory"}
        width={"70%"}
      ></img>
      <Typography variant="h5" mb={2} sx={{ fontWeight: "bold", color: "#304c42" }}></Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}></Typography>
      <Typography variant="h5" mb={2} sx={{ fontWeight: "bold", color: "#304c42" }}>
        Production Facilities Ownership
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        Outland Denim is dedicated to owning and operating its own production facilities, emphasizing full control and
        ethical oversight of its manufacturing processes. By maintaining a Cut and Sew Facility in Cambodia, Outland
        Denim can ensure that its employees receive a living wage, creating a work environment that aligns with their
        values of justice and equity. This commitment enables the company to maintain transparency and accountability
        across all production stages, from design to distribution. By directly owning and operating their production
        facilities, Outland Denim demonstrates a strong commitment to their mission of setting the highest standards in
        social, environmental, and economic sustainability within the fashion industry. This approach allows them to
        foster a future free from exploitation and to promote ethical practices and partnerships throughout their supply
        chain.
      </Typography>
      <img
        src={SewingMachine}
        height={"auto"}
        alt={"sewing machine"}
        width={"70%"}
      ></img>
      <Typography variant="h5" mb={2} sx={{ fontWeight: "bold", color: "#304c42" }}></Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}></Typography>
      <img
        src={TextileFactory}
        height={"auto"}
        alt={"textile factory"}
        width={"70%"}
      ></img>
      <Typography variant="h5" mb={2} sx={{ fontWeight: "bold", color: "#304c42" }}>
        Fair Wages
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        Outland Denim adopts the Global Coalition's definition of a living wage, which is remuneration received for a
        standard workweek by a worker in a particular place. This wage should be sufficient to afford a decent standard
        of living for the worker and their family. It covers essential needs such as food, water, housing, education,
        healthcare, transportation, clothing, and provision for unexpected events. &nbsp; In summary, Outland Denim is
        actively working to provide a living wage to its employees in Cambodia by utilizing a comprehensive methodology,
        collaborating with local organizations, and exploring third-party research projects. They are dedicated to
        ensuring their employees can afford a decent standard of living and are actively seeking ways to improve their
        wage practices over time. This commitment is an integral part of their ethical and sustainable business
        practices.
      </Typography>
      <Typography variant="h5" mb={2} sx={{ fontWeight: "bold", color: "#304c42" }}>
        Ecofriendly Materials
      </Typography>
      <Typography mb={2} variant="body1" sx={{ fontWeight: "normal" }}>
        Outland Denim is deeply committed to sustainability in their materials, sourcing organic cotton from Turkey,
        which is grown without GMOs, artificial fertilizers, or pesticides. Their fabric suppliers, such as Bossa Denim
        and Kadeks, prioritize eco-friendliness by avoiding harmful chemicals in production processes. In addition, some
        fabric mills employ solar energy systems, reducing their carbon footprint. Hand-loom artisans are utilized for
        cotton t-shirt fabric, promoting traditional craftsmanship and sustainability. They incorporate recycled
        materials into trims and accessories, with some featuring 35% recycled content. Further, OekoTex Standard 100
        Class 1 certification ensures the safety and eco-friendliness of their materials. These practices collectively
        underline Outland Denim's holistic approach to sustainable materials, benefiting both the environment and
        responsible practices in the fashion industry.
      </Typography>
      <img
        src={JeansMeasuring}
        height={"auto"}
        alt={"Measuring Jeans"}
        width={"70%"}
      ></img>
    </>
  );
}

export default OutlandDenim;
