import React from "react";
import { Grid, Paper, Chip, Container } from "@mui/material";
import SearchBar from "../components/SearchBar";
import FilterDropdown from "../components/FilterDropdown";
import {
  labourFilters,
  planetFilters,
  materialsFilters,
  clothingFilters,
  filters,
  communityFilters,
} from "../resources/filters";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: "#faf3e8",
    fontWeight: "normal",
    margin: 4,
    fontSize: "15px",
    "&&:hover": {
      backgroundColor: "#e5c185",
      "& svg": {
        stroke: "#e5c185 !important",
      },
    },
  },
}));

function Filters(props) {
  const classes = useStyles();
  const { selected, resetAll, updateSelected } = props;

  const filters = Array.from(selected).map((data) => {
    return (
      <Chip
        className={classes.chip}
        key={data}
        label={data}
        onDelete={() => updateSelected(data)}
        onClick={() => updateSelected(data)}
        deleteIcon={
          <CloseIcon
            style={{
              color: "#222222",
              fontSize: 20,
              stroke: "#faf3e8",
              strokeWidth: 1,
            }}
          />
        }
      />
    );
  });
  return (
    <>
      <ResetAllButton resetAll={resetAll} />
      {filters}
    </>
  );
}

function ResetAllButton(props) {
  const { resetAll } = props;
  const classes = useStyles();

  return (
    <Chip
      className={classes.chip}
      key={"Reset All"}
      label={"Reset All"}
      onClick={resetAll}
      sx={{ backgroundColor: "#f79494 !important" }}
    />
  );
}

function FilterBox(props) {
  const { setQuery, selected, resetAll, updateSelected } = props;
  const classes = useStyles();

  return (
    <Container maxWidth="md">
      <Paper
        sx={{
          display: "flex",
          justifyContent: "left",
          flexWrap: "wrap",
          listStyle: "none",
          p: 0.5,
          m: 0,
          width: "100%",
          boxShadow: "none",
        }}
      >
        {selected.size > 0 ? (
          <Filters
            selected={selected}
            resetAll={resetAll}
            updateSelected={updateSelected}
          />
        ) : (
          <Chip
            className={classes.chip}
            sx={{ backgroundColor: "white !important", pointerEvents: "none" }}
          />
        )}
      </Paper>
      <Grid container alignItems={"center"}>
        <FilterDropdown
          header={filters.Planet}
          options={planetFilters}
          updateSelected={updateSelected}
          selected={selected}
        />
        <FilterDropdown
          header={filters.Labour}
          options={labourFilters}
          updateSelected={updateSelected}
          selected={selected}
        />
        <FilterDropdown
          header={filters.Community}
          options={communityFilters}
          updateSelected={updateSelected}
          selected={selected}
        />
        <FilterDropdown
          header={filters.Materials}
          options={materialsFilters}
          updateSelected={updateSelected}
          selected={selected}
        />
        <FilterDropdown
          header={filters.Clothing}
          options={clothingFilters}
          updateSelected={updateSelected}
          selected={selected}
        />
        <SearchBar setQuery={setQuery}></SearchBar>
      </Grid>
    </Container>
  );
}

export default FilterBox;
