import React from "react";
import Meta from "./../components/Meta";
import FilterPage from "./filter"
import NewsletterSection from "../components/NewsletterSection";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <FilterPage />
      <NewsletterSection bgColor="default" size="medium"/>
    </>
  );
}

export default IndexPage;
